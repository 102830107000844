export const environment = {
  env: 'ppd',
  production: true,
  apiUrl: '/api',
  loginUrl: '/login/keycloak',
  loginUrlSilent: '/login/keycloak?silent',
  logoutUrl: 'https://auth.dev.birdz.com/realms/AppsByBirdz/protocol/openid-connect/logout',
  bypassOAuth: false,
  sentryDSN:
    'https://226f4791bdd43a18ba5f7e84aaf36083@o4509037256900608.ingest.de.sentry.io/4509042987171920',
  sentryTracingOrigins: [
    'https://bdz-dtp-supervision-ppd.appspot.com',
    'https://indicators.ppd.apps.birdz.com',
  ],
  sentryTracesSampleRate: 0.0,
  messagesUrl: '/api/birdz-messenger/messages',
  messagesCookiesDomain: null,
  pdsUrl: (pds: string) => `https://pds.ppd.apps.birdz.com?pds=${pds}`,
  redirectToDomain: 'indicators.ppd.apps.birdz.com',
};
